.param {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.param__label {
  padding-top: 2px;
  font-size: 14px;
  line-height: 16px;
}

.param__widget {
  margin-left: auto;
  padding-left: 20px;
}

.param__receipt__label {
  padding-top: 2px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 3%;
}

.param__receipt {
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (900px <= width < 1500px) {
  .param {
    margin-top: 8px;
  }

  .param__label {
    /*padding-top: 2px;*/
    font-size: 13px;
    line-height: 15px;
  }

  .param__widget {
    padding-left: 14px;
  }
}

.param--larger {
}

.param--larger .param__label {
  font-size: 16px;
  line-height: 20px;
}

.param--larger .param__widget {
}

@media (900px <= width < 1500px) {
  .param--larger {
  }

  .param--larger .param__label {
    font-size: 14px;
    line-height: 16px;
  }

  .param--larger .param__widget {
  }
}
