.a5-voucher {
  --padding: 33px;
}

.a5-voucher {
  position: relative;
  /* size is in relation to 96 ppi/dpi: */
  /* https://www.a4-size.com/a4-size-in-pixels/?size=a4&unit=px&ppi=96 */
  width: 559px;
  height: 793px; /* it has to be 794, but when 794 is set the plugin crashes */
  margin-left: auto;
  margin-right: auto;
  /* font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: "Questrial", sans-serif;
}

.a5-voucher__body {
  height: 100%;
}

.a5-voucher__inner {
  height: 100%;
  padding: 25px 25px 0;
}

.a5-voucher__panel {
  height: 100%;
  padding: 50px;
  background-color: #ffffff;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

.a5-voucher__logo {
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a5-voucher__logo img {
  height: 100%;
  width: auto;
  max-width: 320px;
  border-radius: 12px;
  object-fit: contain;
}

.a5-voucher__slogan {
  margin-top: 40px;
  font-size: 30px;
  line-height: 42px;
  max-height: 84px;
  text-align: center;
}

.a5-voucher__join {
  margin-top: 20px;
  text-align: center;
}

.a5-voucher__join img {
  height: 300px;
}

.a5-voucher__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
