.qr-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 24px 24px 24px 84px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #000000;
    transition: background-color var(--transition-hover),
    color var(--transition-hover);
}

.qr-button:hover {
    background-color: #F7B33B;
    color: #FFFFFF;
}

.qr-button__icon {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    fill: currentColor;
}

.qr-button__label {

}


.qr-button--disabled {
    box-shadow: none;
    pointer-events: none;
    background-color: #DDDDDD;
    color: #5D6164;
    opacity: 50%;
}


@media (900px <= width < 1500px) {

    .qr-button {
        border-radius: 9px;
        padding: 16px 16px 16px 40px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
        font-size: 15px;
        line-height: 20px;
    }

    .qr-button__icon {
        left: 10px;
        width: 40px;
        height: 40px;
        margin-top: -20px;
    }

    .qr-button__label {

    }
}
