.form {
  padding: 36px 25px;
}

.form fieldset {
  padding: 0;
  margin: 0;
  border: 0 none;
}

.title {
  font-weight: 900;
  font-size: 28px;
  line-height: 1.36;
  margin-bottom: 24px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  margin-bottom: 16px;
}

.img_upload {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 16px;
  /* background-color: aqua; */
}

.subfields {
  display: flex;
  flex-direction: row;
  color: #5d6164;
  font-size: 16px;
  gap: 3px;
}

.field--error input,
.field--error select {
  border-color: #d40000;
}

.field--error .field__helper-text {
  color: #d40000;
}

.field__helper-text {
  font-size: 12px;
  color: #5d6164;
}

.buttons {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 1fr 1fr; */
  /* gap: 10px; */
}
