.business {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 23px 16px 24px;
  background-color: #ffffff;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-grey-background);
  position: relative;
  /* margin-top: 4px; */
}

.business--selected {
  box-shadow:
    0 0 0 6px var(--brand),
    var(--shadow-over-grey-background);
}

.business__corner-buttons {
  margin-top: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.business__corner-buttons a {
  border: 2px solid #c9c9c9;
  color: black;
  padding: 5px 65px;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: var(--shadow-over-grey-background); */
}
.business__corner-buttons a:hover {
  background-color: #eee;
}

.business__head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 10px;
  /* grid-column-gap: 0px; */
}

.business__preview {
  overflow: hidden;
  border-radius: 8px;
}

a.business__preview:hover {
  filter: brightness(1.05) contrast(1.05);
}

.business__logo {
  vertical-align: top;
  width: 100%;
  height: auto;
}

.business__summary {
}

.business__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 22px;
}

.business__link {
  color: inherit;
  text-decoration: none;
  transition: color var(--transition-hover);
}

.business__link:hover {
  color: var(--brand);
}

.business__industry {
  margin-top: 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #5d6164;
}

.business__location {
  margin-top: 6px;
}

.business__description {
  margin-top: 12px;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 18px;
}

.business__footer {
  margin-top: auto;
  padding-top: 8px;
}

.business__sub-heading {
  margin-top: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.business__actions {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@keyframes fadeInScaleUp {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (900px <= width < 1500px) {
  .business {
    /*height: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    padding: 15px 14px 18px;
    /*background-color: #FFFFFF;*/
    /*border-radius: var(--card-radius);*/
    /*box-shadow: var(--shadow-over-grey-background);*/
    animation: fadeInScaleUp 0.3s ease-in-out forwards;
  }

  .business--selected {
    box-shadow:
      0 0 0 4px var(--brand),
      var(--shadow-over-grey-background);
    transition: box-shadow 0.3s ease-in-out; /* Add this line */
  }

  .business__head {
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
    /*align-items: flex-end;*/
    gap: 8px;
  }

  .business__preview {
    /*overflow: hidden;*/
    border-radius: 6px;
  }

  .business__logo {
    /*vertical-align: top;*/
    /*width: 100%;*/
    /*height: auto;*/
  }

  .business__summary {
  }

  .business__heading {
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
    /*font-weight: var(--extra-bold);*/
    font-size: 16px;
    line-height: 20px;
  }

  .business__link {
    /*color: inherit;*/
    /*text-decoration: none;*/
    /*transition: color var(--transition-hover);*/
  }

  .business__link:hover {
    /*color: var(--brand);*/
  }

  .business__industry {
    /*margin-top: 4px;*/
    /*font-weight: bold;*/
    /*font-size: 10px;*/
    /*line-height: 12px;*/
    /*color: #5D6164;*/
  }

  .business__location {
    /*margin-top: 6px;*/
  }

  .business__description {
    /*margin-top: 12px;*/
    /*flex-shrink: 0;*/
    font-size: 13px;
    line-height: 17px;
  }

  .business__footer {
    /*margin-top: auto;*/
    padding-top: 8px;
  }

  .business__sub-heading {
    margin-top: 12px;
    /*font-weight: bold;*/
    font-size: 14px;
    line-height: 18px;
  }

  .business__actions {
    margin-top: 8px;
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
    gap: 16px;
  }
}
