.a4-voucher {
  --padding: 33px;
}

.a4-voucher {
  position: relative;
  /* size is in relation to 96 ppi/dpi: */
  /* https://www.a4-size.com/a4-size-in-pixels/?size=a4&unit=px&ppi=96 */
  width: 794px;
  height: 1122px; /* it has to be 1123, but when 1123 is set the plugin crashes */
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  background-image: linear-gradient(transparent, var(--brand));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Questrial", sans-serif;
}

.a4-voucher__body {
  height: 100%;
}

.a4-voucher__head {
  padding: 47px var(--padding) 24px;
}

.a4-voucher__heading {
  font-weight: bold;
  font-size: 66px;
  line-height: 66px;
  color: #ffffff;
  white-space: nowrap;
}

.a4-voucher__wizard {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 28px;
}

.a4-voucher__tile {
  display: flex;
  height: 294px;
  flex-direction: column;
  background-image: linear-gradient(
    rgb(255 255 255 / 100%),
    rgb(255 255 255 / 74%)
  );
  border-radius: 19px;
}

.a4-voucher__illustration {
  height: 100%;
  padding: 13px 20px 20px 12px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.a4-voucher__tile--scan .a4-voucher__illustration {
  background-image: url("images/a4-voucher-action-scan.png");
}

.a4-voucher__tile--otp .a4-voucher__illustration {
  background-image: url("images/a4-voucher-action-otp.png");
}

.a4-voucher__tile--pass .a4-voucher__illustration {
  background-image: url("images/a4-voucher-action-pass.png");
}

.a4-voucher__action {
  font-size: 19px;
  line-height: 26px;
}

.a4-voucher__main {
  display: flex;
  align-items: center;
  padding: 30px var(--padding);
  background-color: rgb(0 0 0 / 50%);
}

.a4-voucher__summary {
  flex-grow: 1;
}

.a4-voucher__intro {
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.a4-voucher__logo {
  margin-top: 20px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a4-voucher__logo img {
  height: 100%;
  width: auto;
  max-width: 320px;
  border-radius: 16px;
  object-fit: contain;
}

.a4-voucher__join {
  width: 300px;
  height: 300px;
  border: 2px solid #ffffff;
  border-radius: 18px;
  padding: 20px;
}

.a4-voucher__join img {
  width: 100%;
  height: auto;
}

.a4-voucher__offer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 166px;
  padding: 22px var(--padding);
  font-size: 44px;
  line-height: 61px;
  text-align: center;
  color: #ffffff;
}

.a4-voucher__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
