.locations-creation-input-title {
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
}

.locations-creation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-select-selection-placeholder {
  color: #f0f0f0;
}

.create-location-save-btn {
  width: 100%;
  margin-bottom: 5px;
}
