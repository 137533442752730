.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth--login-screen {
  width: 384px;
  padding: 102px 42px;
  min-height: 500px;
}



.auth--otp-verification {
  width: 384px;
  padding: 82px 42px 62px;
  min-height: 500px;
}

.auth--set-name {
  width: 384px;
  padding: 62px 42px;
  min-height: 440px;
}

.auth__fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.auth__main-heading {
  font-weight: var(--extra-bold);
  font-size: 36px;
  line-height: 42px;
  color: var(--charcoal);
}

.auth__sub-heading {
  margin-top: -4px;
  display: block;
  font-weight: normal;
  font-size: 0.9em;
}

.auth__main-note {
  margin-top: 17px;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 24px;
  color: #5d6164;
}

.auth__main-heading:has(.auth__sub-heading) + .auth__main-note {
  margin-top: 6px;
}

.auth__heading {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: var(--charcoal);
}

.auth__note {
  margin-top: 12px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.auth__panel {
  margin-top: 16px;
  padding: 20px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.auth__field {
  margin-top: 16px;
}

.auth__field:first-child {
  margin-top: 0;
}

.auth__optional-turnstile-capcha {
}

.auth__optional-turnstile-capcha .turnstile--interactive {
  margin-top: 16px;
}

.auth__optional-turnstile-capcha iframe {
  vertical-align: top;
}

.auth__swap-method {
  display: block;
  width: fit-content;
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 8px 0;
  /* font-family: inherit; */
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__swap-method + .auth__field {
  margin-top: 8px;
}

.auth__submit {
  margin-top: 35px;
}

.auth__terms-and-conditions {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-grey);
}

.auth__terms-link {
  padding-top: 4px;
  padding-bottom: 4px;
  color: inherit;
  text-underline-offset: 3px;
  transition: text-decoration-color var(--transition-hover);
}

.auth__terms-link:hover {
  text-decoration-color: transparent;
}

.auth__alternation {
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #5d6164;
  text-align: center;
}

.auth__alt-action {
  vertical-align: baseline;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  /* font-family: inherit; */
  font-weight: var(--extra-bold);
  font-size: 14px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__code-note {
}

.auth__code-hint {
  margin-top: 12px;
  font-weight: var(--light);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
