.social-voucher-poster {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-voucher-poster-container {
  position: relative;
  width: 500px; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
  background-color: #5a3825; /* Default brown background color */
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-poster-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  padding: 30px 20px;
}

.social-poster-download-button {
}

.social-poster-close-button {
  position: fixed; /* Fixed positioning */
  top: -10%; /* Position at the top */
  left: 0; /* Position on the left */
  padding: 10px; /* Add some padding */
  z-index: 100; /* Ensure it's above other elements */
}

.social-voucher-top-left-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 20px;
}

.social-voucher-offer {
  background: #e06932;
  font-size: 40px;
  text-align: left;
}

.social-voucher-title {
  margin: 0 0 0 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.social-voucher-logo {
  width: 50px;
  height: auto;
  border-radius: 10px;
}

.social-voucher-text-section {
  padding-left: 20px;
  margin-top: 0px; /* Adjust to position text properly */
  text-align: center;
  color: white;
  overflow: visible;
  z-index: 1;
}

.social-voucher-place-name {
  text-align: left;
  margin: 10px 0;
  font-size: 40px;
  width: 150px;
  margin-bottom: 50px;
  line-height: 100%;
  font-weight: bolder;
  text-shadow: black 0px 0px 1px;
}

.social-voucher-image-mask {
  height: 100%;
  width: auto;
  display: absolute;
  align-items: right;
  justify-content: right;
  border-radius: 800px 0 0 800px;
  overflow: hidden;
}

.social-voucher-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-voucher-visit-button {
  position: absolute;
  background-color: white;
  color: black;
  border: none;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  left: 20px;
  bottom: 80px;
  box-shadow: gray 0px 0px 1px;
  width: 220px;
  height: 50px;
}

.powered-by-meed {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
}
