.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 23px 16px 24px;
  background-color: #ffffff;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-grey-background);
}

.promo__auto-voucher {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background-image: url("autovoucher.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  border-top-right-radius: var(--card-radius);
}

.promo__auto-voucher .tooltip-context {
  height: 100%;
}

.promo__auto-voucher .tooltip {
  margin-bottom: 10px;
  margin-right: -8px;
}

.promo__summary {
}

.promo__data {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  box-shadow: var(--shadow-over-white-background);
  border-radius: 5px;
}

.promo__stat {
  padding-top: 6px;
  padding-bottom: 2px;
}

.promo__stat:first-child {
  border-right: 1px solid #b7bdc2;
  padding-right: 10px;
}

.promo__stat:last-child {
  margin-left: -1px;
  border-left: 1px solid #b7bdc2;
  padding-left: 10px;
}

.promo__param {
  font-weight: 600;
  font-size: 13px;
  line-height: 10px;
  text-align: center;
}

.promo__value {
  margin-top: 10px;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
}

.promo__operations {
  margin-top: auto;
  padding-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media (900px <= width < 1500px) {
  .promo {
    /*display: flex;*/
    /*flex-direction: column;*/
    padding: 15px 13px 20px;
    /*background-color: #FFFFFF;*/
    /*box-shadow: var(--shadow-over-grey-background);*/
  }

  .promo__summary {
  }

  .promo__data {
    margin-top: 16px;
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
    padding: 2px 1px;
    /*box-shadow: var(--shadow-over-white-background);*/
    /*border-radius: 5px;*/
  }

  .promo__stat {
    /*padding-top: 6px;*/
    /*padding-bottom: 2px;*/
  }

  .promo__stat:first-child {
    /*border-right: 1px solid #B7BDC2;*/
    padding-right: 6px;
  }

  .promo__stat:last-child {
    /*margin-left: -1px;*/
    /*border-left: 1px solid #B7BDC2;*/
    padding-left: 6px;
  }

  .promo__param {
    font-size: 11px;
    line-height: 10px;
    text-align: center;
  }

  .promo__value {
    margin-top: 8px;
    font-size: 22px;
    line-height: 20px;
    padding-bottom: 5px;
  }

  .promo__operations {
    /*margin-top: auto;*/
    /*padding-top: 12px;*/
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
    gap: 16px;
  }
}
