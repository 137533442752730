.fieldset {
  border: 0 none;
  padding: 0;
}

.unauthorized-access-warning {
  background: #f9d7da;
  color: #721c23;
  border: 1px solid #721c23;
  border-radius: 10px;
  padding: 10px;
  margin-top: 40px;
  text-align: center;
}

.unauthorized-access-warning__title {
  font-weight: bold;
  margin-bottom: 10px;
}
.unauthorized-access-warning__text {
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 26px 0px 0px;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggleSwitch input:checked + .slider {
  background-color: #e06932; /* Or your preferred color */
}

.toggleSwitch input:checked + .slider:before {
  transform: translateX(24px);
}

.toggleLabel {
  font-size: 18px;
  font-weight: 900;
  color: #666; /* Or your preferred color */
}
