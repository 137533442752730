.a6-voucher {
  --padding: 33px;
}

.a6-voucher {
  position: relative;
  /* size is in relation to 96 ppi/dpi: */
  /* https://www.a4-size.com/a4-size-in-pixels/?size=a4&unit=px&ppi=96 */
  width: 397px; /* it has to be 559, but when 559 is set the plugin crashes */
  height: 559px;
  margin-left: auto;
  margin-right: auto;
  /* font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: "Questrial", sans-serif;
}

.a6-voucher__body {
  height: 100%;
  padding: 30px 20px;
}

.a6-voucher__header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.a6-voucher__logo {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a6-voucher__logo img {
  height: 100%;
  width: auto;
  max-width: 320px;
  border-radius: 8px;
  object-fit: contain;
}

.a6-voucher__brand {
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.a6-voucher__panel {
  margin-top: 22px;
  padding: 30px 0 0;
  background-color: #ffffff;
  border-radius: 20px;
}

.a6-voucher__join {
  width: 270px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
}

.a6-voucher__join img {
  width: 100%;
  height: auto;
}

.a6-voucher__scan-me {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 32px;
  line-height: 34px;
  text-align: center;
}

.a6-voucher__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
