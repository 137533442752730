.helper {
    padding-top: 6px;
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
}

.helper--error {
    color: var(--danger);
}

.helper + .helper {
    padding-top: 4px;
}


@media (900px <= width < 1500px) {

    .helper {
        padding-top: 5px;
        font-size: 12px;
        line-height: 16px;
    }

    .helper + .helper {
        padding-top: 3px;
    }
}
