@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/nunito-sans-v15-latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/src/assets/fonts/nunito-sans-v15-latin-600.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/src/assets/fonts/nunito-sans-v15-latin-700.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/src/assets/fonts/nunito-sans-v15-latin-800.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: url("/src/assets/fonts/nunito-sans-v15-latin-900.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular A SemiBold";
  src: url("/src/assets/fonts/Euclid Circular A SemiBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A SemiBold Italic";
  src: url("/src/assets/fonts/Euclid Circular A SemiBold Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Regular";
  src: url("/src/assets/fonts/Euclid Circular A Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Medium";
  src: url("/src/assets/fonts/Euclid Circular A Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Medium Italic";
  src: url("/src/assets/fonts/Euclid Circular A Medium Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Light";
  src: url("/src/assets/fonts/Euclid Circular A Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Light Italic";
  src: url("/src/assets/fonts/Euclid Circular A Light Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Italic";
  src: url("/src/assets/fonts/Euclid Circular A Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Bold";
  src: url("/src/assets/fonts/Euclid Circular A Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A Bold Italic";
  src: url("/src/assets/fonts/Euclid Circular A Bold Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
