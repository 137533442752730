.separator {
    margin: var(--spacing) 12px;
    height: 1px;
    background-color: currentColor;
}

@media (900px <= width < 1500px) {

    .separator {
        margin-left: 5px;
        margin-right: 5px;
    }
}