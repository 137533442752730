.explainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 4px;
  margin: 0 4px;
  cursor: help;
}

.explainer__icon {
  vertical-align: middle;
  height: auto;
  width: 20px;
}

.explainer__tooltip {
  position: absolute;
  z-index: 1000;
  visibility: visible;
  pointer-events: auto;
}

/* Ensure tooltips stay on top and are centered */
.ant-tooltip {
  z-index: 1000 !important;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 10px;
}

.ant-tooltip-inner {
  text-align: center;
  min-width: 250px;
  font-size: 14px;
  padding: 10px 16px;
}

/* Parent containers should not clip tooltips */
.milestone-stamp__section,
.milestone-stamp__sub-heading,
.milestone-stamp.container,
.milestone-stamp__body {
  position: relative;
  overflow: visible !important;
}
