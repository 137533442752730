.outer-close {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: #ffffff;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.outer-close:hover {
  background-color: #f6f6f8;
}

.outer-close__cross {
  width: 16px;
  height: 16px;
  fill: var(--brand);
}

.outer-close--larger {
  width: 30px;
  height: 30px;
}

.outer-close--larger .outer-close__cross {
  width: 20px;
  height: 20px;
}
