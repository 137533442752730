.dashboard {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard.container {
  max-width: 1064px;
}

.dashboard__heading {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: start;
  align-items: center;
}

.dashboard__tieralert {
  flex: 1;
  margin-left: 20px;
  padding: 10px;
  background-color: #ffdede;
  font-size: 14px;
  border-radius: 10px;
  font-weight: normal;
  border: 2px solid #ff3636;
}

.dashboard__summary {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.dashboard__stat {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 23px 24px 20px 112px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
}

@media (1500px <=width) and (900px <=height) {
  .dashboard__stat {
    padding-top: 33px;
    padding-bottom: 30px;
  }
}

.dashboard__illustration {
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -50px;
  width: 100px;
  height: 100px;
}

.dashboard__param {
  flex-grow: 1;
  display: flex;
  align-items: center; /* In case "Active Vouchers" takes two lines, but Total Vouchers just one. Make then centered at least. */
  justify-content: flex-end;
  font-weight: var(--black);
  font-size: 24px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.8px;
}

.dashboard__stat-line-break {
  display: none;
}

.dashboard__value {
  margin-top: 14px;
  font-weight: bold;
  font-size: 52px;
  line-height: 52px;
  text-align: right;
}

.dashboard__panel {
  margin-top: 24px;
  padding: 10px 21px;
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: var(--primary-colour);
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
}

.dashboard__calendar {
  vertical-align: top;
  fill: #ffffff;
}

.dashboard__date-time {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.dashboard__create {
  margin-left: auto;
  display: inline-flex;
  vertical-align: top;
  gap: 9px;
  padding: 9px 19px 9px 9px;
  border: none;
  background-color: #ffffff;
  border-radius: 20px;
  font-family: inherit;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.dashboard__create:hover {
  background-color: #eeeeee;
  box-shadow: var(--shadow-over-white-background);
}

.dashboard__create--disabled,
.dashboard__create--disabled:hover {
  background-color: #ddd;
}

.dashboard__create--disabled > * {
  opacity: 0.6;
}

.dashboard__plus {
  fill: var(--primary-colour);
  width: 26px;
  height: 26px;
}

.dashboard__body {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.dashboard__item {
  position: relative;
  height: 300px;
  border: 26px solid transparent;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
}

@media (1500px <=width) {
  @media (850px <=height < 900px) {
    .dashboard__item {
      height: 350px;
    }
  }

  @media (900px <=height < 950px) {
    .dashboard__item {
      height: 400px;
    }
  }

  @media (950px <=height < 1000px) {
    .dashboard__item {
      height: 450px;
    }
  }

  @media (1000px <=height) {
    .dashboard__item {
      height: 482px;
    }
  }
}

.dashboard__sub-heading {
  margin-top: -3px;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}

.dashboard__growth {
  position: absolute;
  left: 0;
  bottom: 16px;
  font-weight: var(--black);
  font-size: 128px;
  line-height: 128px;
}

.dashboard__time-frame {
  position: absolute;
  left: 10px;
  bottom: -6px;
  font-size: 24px;
  line-height: 32px;
}

.dashboard__arrow {
  position: absolute;
  right: 12px;
  bottom: 34px;
  fill: var(--primary-colour);
}

@media (900px <=width < 1500px) {
  .dashboard {
  }

  .dashboard.container {
    max-width: 900px;
  }

  .dashboard__heading {
  }

  .dashboard__summary {
    margin-top: 18px;
    gap: 9px;
  }

  .dashboard__stat {
    padding: 20px 18px 18px 84px;
  }

  .dashboard__illustration {
    left: 9px;
    top: 50%;
    margin-top: -38px;
    width: 75px;
    height: 75px;
  }

  .dashboard__param {
    font-size: 20px;
    line-height: 22px;
  }

  .dashboard__value {
    margin-top: 5px;
    font-size: 40px;
    line-height: 40px;
  }

  .dashboard__panel {
    margin-top: 18px;
    padding: 8px 16px;
    gap: 12px;
    border-radius: 12px;
  }

  .dashboard__calendar {
  }

  .dashboard__date-time {
    font-size: 16px;
    line-height: 20px;
  }

  .dashboard__create {
    gap: 7px;
    padding: 7px 14px 7px 7px;
    border-radius: 15px;
    font-size: 18px;
    line-height: 22px;
  }

  .dashboard__create:hover {
  }

  .dashboard__plus {
    width: 22px;
    height: 22px;
  }

  .dashboard__body {
    margin-top: 18px;
    gap: 30px;
  }

  .dashboard__item {
    height: 240px;
    border: 20px solid transparent;
    border-radius: 12px;
  }

  @media (650px <=height < 700px) {
    .dashboard__item {
      height: 260px;
    }
  }

  @media (700px <=height < 750px) {
    .dashboard__item {
      height: 300px;
    }
  }

  @media (750px <=height) {
    .dashboard__item {
      height: 350px;
    }
  }

  .dashboard__sub-heading {
    margin-top: -2px;
    font-size: 27px;
    line-height: 36px;
  }

  .dashboard__growth {
    bottom: 12px;
    font-size: 96px;
    line-height: 96px;
  }

  .dashboard__time-frame {
    left: 8px;
    bottom: -4px;
    font-size: 18px;
    line-height: 24px;
  }

  .dashboard__arrow {
    right: 9px;
    bottom: 25px;
  }
}

@media (width < 900px) {
  .dashboard {
  }

  .dashboard.container {
    max-width: none;
  }

  .dashboard__heading {
  }

  .dashboard__summary {
  }

  .dashboard__stat {
    padding: 14px 14px 20px;
  }

  .dashboard__illustration {
    position: static;
    margin-left: -10px;
    margin-top: -12px;
  }

  .dashboard__param {
    margin-top: -12px;
    font-size: 24px;
    line-height: 33px;
  }

  .dashboard__stat-line-break {
    display: inline;
  }

  .dashboard__value {
  }

  .dashboard__panel {
    padding-right: 13px;
  }

  .dashboard__calendar {
  }

  .dashboard__date-time {
  }

  .dashboard__create {
  }

  .dashboard__create:hover {
  }

  .dashboard__plus {
  }

  .dashboard__body {
    display: block;
  }

  .dashboard__item {
    margin-top: 24px;
    height: 214px;
  }

  .dashboard__item:first-child {
    margin-top: 0;
  }

  .dashboard__sub-heading {
    margin-top: -14px;
  }

  .dashboard__growth {
    position: absolute;
    left: auto;
    right: 0;
    bottom: 36px;
    text-align: right;
  }

  .dashboard__time-frame {
    position: absolute;
    left: auto;
    right: 0;
    bottom: 4px;
  }

  .dashboard__arrow {
    position: absolute;
    left: 0;
    right: auto;
    bottom: 0;
    width: 60px;
    height: 60px;
  }
}
