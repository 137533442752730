.powered-by-meed {
  height: 141px; /* Biggest case for A4 variant */
  background-image: url("Meed-Table Flyer light footer1-bleed.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.powered-by-meed.template-2,
.powered-by-meed.template-3 {
  background-image: url("Meed-Table Flyer dark footer1-1bleed.svg");
}
