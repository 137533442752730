:root {
  --brand: #e06932;
  --primary-colour: #e06932;
  --primary-colour-hover: #ff8b5b;
  --gradient-colour-1: #da4509;
  --gradient-colour-2: #f8bf78;
  --font: "Nunito Sans", sans-serif;
  --light: 300;
  --extra-bold: 800;
    --black: 900; /* font weight, not color */
    --tooltip-background: rgb(0 0 0 / 90%);

    --ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
    --transition-hover: 100ms ease;
    --transition-dropdown: 200ms ease;
    --transition-show: 300ms var(--ease-out-quint);

    --card-radius: 15px;
    --input-radius: 10px;

    --image-fallback: #F7F6FB;
    --input-border: #5D6164;
    --input-border-hover: var(--primary-colour);
  --placeholder-color: #5d6164;
  --disabled-background: #f6f6f6;
  --disabled-color: #5d6164;
  --choice-border: #bababa;
  --choice-border-hover: #d9d9d9;
  --success: #2ecc71;
  --danger: #ff0033;

  --grey: #b7bdc2;
  --dark-grey: #5d6164;
  --hover: rgb(183 189 194 / 30%); /* good for small elements such as buttons */
  --hover-lighter: rgb(
    183 189 194 / 15%
  ); /* good for big elements such as dropdown menu items */
  --active: rgb(183 189 194 / 45%);
  --active-lighter: rgb(183 189 194 / 22.5%);

  --white-hover: #fafafa;

  --faded-color: var(--brand);

  --color: var(--brand); /* color for _patterns.css */
  --color-50pct: rgb(248 124 86 / 50%); /* color for _patterns.css */
}

@media (width < 740px) {
  :root {
        --card-radius: 12px;
        --input-radius: 10px;
    }
}

/* Commonly used padding */

:root {
    --spacing: 24px;
}

@media (900px <= width < 1500px) {

    .voucher {
        --spacing: 16px;
    }
}


/* Sidebar width is fluid: 67-100px. It is based on screen height (no width)
 * See sidebar.css for details. Drag screen size (vertically) to see it in action.
 */
:root {
  --sidebar-width: 100px;
}

@media (min-height: 874px) and (max-height: 1120px) {
  :root {
    --sidebar-width: 75px;
  }
}

@media (max-height: 873px) {
  :root {
    --sidebar-width: 67px;
  }
}

/* Shadows
 * Note that in figma the same shadow is used all over the project. But it
 * doesn't look good over white background. Top part can't be seen over it.
 */

:root {
  --shadow-over-grey-background: 0 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-over-white-background: 0 2px 4px rgba(0, 0, 0, 0.25);
  --shadow-over-white-background-stronger: 0 4px 8px rgba(0, 0, 0, 0.25);
}

@media (max-height: 789px) {
  :root {
    --shadow-over-grey-background: 0 3px 3px rgba(0, 0, 0, 0.25);
    --shadow-over-white-background: 0 1px 3px rgba(0, 0, 0, 0.25);
    --shadow-over-white-background-stronger: 0 3px 6px rgba(0, 0, 0, 0.25);
  }
}

