.download {
  position: relative;
  width: 700px;
  height: 443px;
  padding-bottom: 56.25%;
  background-image: url("DownloadApp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
