.analytics {
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.analytics__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.analytics__control-panel {
  margin-top: calc(var(--spacing) - 8px);
  padding-bottom: calc(var(--spacing) / 4);
  display: flex;
  justify-content: space-between;
}

.analytics__actions {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.analytics__date-picker {
  position: relative;
}

.analytics__dropdown {
  position: absolute;
  z-index: 500;
  left: 0;
  top: 100%;
  padding-top: 10px;
}

.analytics__calendar {
  padding: 12px 16px 16px;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: var(--card-radius);
}

.analytics__calendar .rdp {
  margin: 0;
}

.analytics__calendar-close {
  position: absolute;
  right: -14px;
  top: -4px;
}

.analytics__filter {
}

.analytics__body {
  margin-top: var(--spacing);
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 400px;
  gap: 20px;
}

.analytics__sidebar {
  width: 240px;
  flex-shrink: 0;
  padding: 20px 24px 24px;
  background-color: #ffffff;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-grey-background);
}

.analytics__graph {
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-grey-background);
}

.analytics__graph > div {
  flex-grow: 1;
}

.analytics__graph svg {
  vertical-align: top;
  width: 100%;
}

@media (900px <= width < 1500px) {
  .analytics {
  }

  .analytics__heading {
  }

  .analytics__control-panel {
  }

  .analytics__body {
  }

  .analytics__sidebar {
    width: 220px;
  }

  .analytics__graph {
  }
}
