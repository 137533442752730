.loading-spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-spinner__widget {
}
.loading-spinner__illustration {
  position: relative;
  width: 220px;
  height: 220px;
}
.loading-spinner__outer-ring {
}

.loading-spinner__inner-ring {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 224px;
  height: 224px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e06932;
  border-radius: 50%;
  transform: rotate(-90deg);
}
.loading-spinner__inner-ring::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #ff6347; /* Adjust color as needed */
  border-radius: 50%;
  animation: orbit 2s cubic-bezier(0.4, 0.02, 0.58, 1) infinite; /* Adjust duration as needed */
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(111px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(111px) rotate(-360deg);
  }
}

.loading-spinner__logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  vertical-align: top;
}

.loading-spinner__label {
  margin-top: 40px;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #e06932;
}
