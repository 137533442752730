.menu_body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 70%;
  margin: 0 40px;
  padding: 1%;
  gap: 1%;
  /* background-color: aqua; */
}

.menu_headings {
  font-size: 18px;
  font-weight: 450;
  color: #fff;
  cursor: pointer;
  border: none;
  background-color: #333333;
  border-radius: 5px;
  padding: 10px 40px;
  transition:
    background-color 0.3s,
    color 0.3s; /* Add transition */
  /* margin-left: 3.3%; */
  /* margin-bottom: 1%; */
}

.menu_headings:hover {
  background-color: #585858; /* Adjust hover color as needed */
}

.menu_headings.selected {
  color: #fff;
  background-color: var(--primary-colour);
  transition:
    background-color 0.3s,
    color 0.3s; /* Add transition */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .menu_body {
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 1%;
  }

  .menu_headings {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px 20px;
  }
}
