.voucher {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.voucher__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.voucher__filter {
  margin-top: calc(var(--spacing) - 8px);
  padding-bottom: calc(var(--spacing) / 4);
}

.voucher__body {
  margin-top: var(--spacing);
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: var(--spacing);
}

.voucher__body--flex {
  display: flex;
}

.voucher__section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing) var(--spacing) 42px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: var(--shadow-over-grey-background);
}

.voucher__section--focus {
}

.voucher__sub-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.voucher__description {
  margin-top: 9px;
  font-size: 15px;
  line-height: 20px;
  color: #5d6164;
}

.voucher__description--smaller {
  font-size: 14px;
  line-height: 18px;
}

.voucher__inner-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.voucher__widget {
  margin-top: var(--spacing);
}

.separator + .voucher__widget,
.separator + fieldset > .voucher__widget:first-child {
  margin-top: 0;
}

.voucher__widget--disabled {
  position: relative;
}

.voucher__disabling-overlay {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: calc(-1 * var(--spacing) / 2);
  border-radius: 5px;
  background-color: #eff0f1;
  opacity: 50%;
}

.voucher__disabling-tooltip {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: calc(-1 * var(--spacing) / 2);
}

.voucher__disabling-tooltip .tooltip-trigger {
  height: 100%;
}

.voucher__type {
}

.voucher__grid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media (max-width: 1799px) {
  .voucher__grid {
    gap: 12px;
  }
}

.voucher__tile {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  padding: 15px 10px 14px;
  border: none;
  background-color: transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: inherit;
  color: #000000;
  cursor: pointer;
  transition: background-color var(--transition-hover);
}

.voucher__tile:hover {
  background-color: var(--hover);
  color: #333333;
}

.voucher__tile--selected,
.voucher__tile--selected:hover {
  background-color: var(--primary-colour);
  color: #ffffff;
}

.voucher__type-icon {
  vertical-align: top;
  fill: currentColor;
  width: 80px;
  height: 80px;
  transition: fill var(--transition-hover);
}

.voucher__name {
  display: block;
  background-color: transparent;
  font-weight: bold;
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.05em;
}

.voucher__choice {
  min-height: 282px; /* Tallest variant "Discount voucher" -- we want this section to keep it's height. */
}

.voucher__choice .voucher__widget:first-child {
  margin-top: 0;
}

.voucher__preview {
  margin-top: calc(1.5 * var(--spacing));
  display: flex;
  flex-direction: column;
}

.voucher__preview--full {
  flex-grow: 1;
}

.voucher__preview-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.voucher__unit {
  margin-top: 17px;
  flex-shrink: 0;
}

.voucher__preview--full .voucher__unit {
  flex-grow: 1;
}

.voucher__submit {
  margin-top: var(--spacing);
}

.voucher__pagination {
  margin-top: calc(2 * var(--spacing));
}

.voucher__navigation {
  display: none;
}

@media (900px <= width < 1500px) {
  .voucher {
  }

  .voucher__heading {
  }

  .voucher__body {
  }

  .voucher__section {
    padding-bottom: 28px;
    border-radius: 20px;
  }

  .voucher__sub-heading {
  }

  .voucher__description {
    margin-top: 5px;
    font-size: 13px;
    line-height: 18px;
  }

  .voucher__description--smaller {
    font-size: 12px;
    line-height: 16px;
  }

  .voucher__widget {
  }

  .voucher__choice {
    min-height: 231px; /* Tallest variant "Discount voucher" -- we want this section to keep it's height. */
  }

  .voucher__type {
  }

  .voucher__grid {
  }

  .voucher__tile {
    padding: 11px 8px 12px;
    /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);*/
    border-radius: 12px;
  }

  .voucher__type-icon {
    width: 54px;
    height: 54px;
  }

  .voucher__name {
    margin-top: 6px;
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .voucher__preview {
  }

  .voucher__preview-heading {
  }

  .voucher__unit {
    margin-top: 8px;
  }

  .voucher__submit {
  }

  .voucher__pagination {
  }

  .voucher__navigation {
  }
}

@media (width < 900px) {
  .voucher {
    flex-grow: 1;
    /*min-height: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
  }

  .voucher__heading {
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
  }

  .voucher__filter {
    /*margin-top: calc(var(--spacing) - 8px);*/
    /*padding-bottom: calc(var(--spacing) / 4);*/
  }

  .voucher__body {
    /*margin-top: var(--spacing);*/
    /*display: grid;*/
    /*grid-template-columns: repeat(4, minmax(0, 1fr));*/
    /*gap: var(--spacing);*/
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .voucher__section {
    flex-grow: 1;
    padding: var(--spacing) 32px 42px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*padding: var(--spacing) var(--spacing) 42px;*/
    /*background-color: #FFFFFF;*/
    /*border-radius: 30px;*/
    /*box-shadow: var(--shadow-over-grey-background);*/
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: none;
  }

  .voucher__section--mobile-current {
    display: block;
  }

  .voucher__section--focus {
  }

  .voucher__sub-heading {
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
  }

  .voucher__description {
    /*margin-top: 9px;*/
    /*font-size: 15px;*/
    /*line-height: 20px;*/
    /*color: #5D6164;*/
  }

  .voucher__description--smaller {
    /*font-size: 14px;*/
    /*line-height: 18px;*/
  }

  .voucher__inner-heading {
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
  }

  .voucher__widget {
    /*margin-top: var(--spacing);*/
  }

  .voucher__type {
  }

  .voucher__grid {
    /*margin-top: 24px;*/
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
    gap: 12px 36px;
  }

  .voucher__tile {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: stretch;*/
    /*align-items: center;*/
    /*padding: 15px 10px 14px;*/
    /*border: none;*/
    /*background-color: transparent;*/
    /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 15px;*/
    /*font-family: inherit;*/
    /*color: #000000;*/
    /*cursor: pointer;*/
    /*transition: background-color var(--transition-hover);*/
  }

  .voucher__tile:hover {
    /*background-color: var(--hover);*/
    /*color: #333333;*/
  }

  .voucher__tile--selected,
  .voucher__tile--selected:hover {
    /*background-color: var(--primary-colour);*/
    /*color: #FFFFFF;*/
  }

  .voucher__type-icon {
    /*vertical-align: top;*/
    /*fill: currentColor;*/
    /*width: 80px;*/
    /*height: 80px;*/
    /*transition: fill var(--transition-hover);*/
  }

  .voucher__name {
    /*display: block;*/
    /*background-color: transparent;*/
    /*font-weight: bold;*/
    /*margin-top: 6px;*/
    /*font-size: 16px;*/
    /*line-height: 24px;*/
    /*text-align: center;*/
    /*letter-spacing: -0.05em;*/
  }

  .voucher__choice {
    /*min-height: 282px; !* Tallest variant "Discount voucher" -- we want this section to keep it's height. *!*/
  }

  .voucher__choice .voucher__widget:first-child {
    /*margin-top: 0;*/
  }

  .voucher__preview {
    /*margin-top: calc(1.5 * var(--spacing));*/
    /*display: flex;*/
    /*flex-direction: column;*/
  }

  .voucher__preview--full {
    /*flex-grow: 1;*/
  }

  .voucher__preview-heading {
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
  }

  .voucher__unit {
    /*margin-top: 17px;*/
    /*flex-shrink: 0;*/
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .voucher__preview--full .voucher__unit {
    /*flex-grow: 1;*/
  }

  .voucher__submit {
    /*margin-top: var(--spacing);*/
  }

  .voucher__pagination {
    /*margin-top: calc(2 * var(--spacing));*/
  }

  .voucher__navigation {
    display: block;
  }
}

.voucher__section--disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

/* Add styles for disabled inputs within the section */
.voucher__section--disabled input,
.voucher__section--disabled select,
.voucher__section--disabled textarea,
.voucher__section--disabled button {
  pointer-events: none;
}
