.add {
  min-height: 340px;
  width: 100%;
  height: 100%;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #e5e8eb;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: var(--card-radius);
  /* font-family: inherit; */
  font-family: "Questrial", sans-serif;
  letter-spacing: inherit;
  color: inherit;
  cursor: pointer;
  transition: background-color var(--transition-hover);
}

.add:hover {
  background-color: white;
}

.add__icon {
    vertical-align: top;
    width: 118px;
    height: 118px;
}

.add__label {
    margin-top: 42px;
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
}


@media (900px <= width < 1500px) {

    .add {
        min-height: 24px;
        padding: 40px 32px;
    }

    .add__icon {
        width: 90px;
    height: 90px;
  }

  .add__label {
    margin-top: 24px;
    font-size: 18px;
    line-height: 24px;
  }
}

.add--disabled,
.add--disabled:hover {
  background-color: #ddd;
}

.add--disabled > * {
  opacity: 0.6;
}
@media (width < 900px) {
  .add {
    min-height: 198px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .add:hover {
  }

  .add__icon {
  }

  .add__label {
    margin-top: 0;
    margin-left: 47px;
  }
}
