.milestone-stamp.container {
  padding: 24px;
  max-width: 1600px;
  margin: 0 auto;
}

.milestone-stamp__heading {
  margin-bottom: 24px;
}

.milestone-stamp__body {
  display: grid;
  grid-template-columns: 320px minmax(400px, 1fr) 420px;
  gap: 24px;
  overflow-x: hidden;
  max-width: 100%;
  padding: 5px;
}

/* Common section styles */
.milestone-stamp__section {
  position: relative;
  background: white;
  border-radius: 15px;
  padding: 20px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 0;
}

.milestone-stamp__section--focus {
  box-shadow:
    0 0 0 5px var(--orange),
    0 0 5px 5px var(--orange);
  z-index: 1;
}

.milestone-stamp__sub-heading {
  margin-bottom: 20px;
}

.milestone-stamp__widget {
  margin-bottom: 20px;
}

/* Input styles */
.milestone-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.milestone-input:focus {
  outline: none;
  border-color: var(--orange);
}

textarea.milestone-input {
  resize: vertical;
  min-height: 80px;
  line-height: 1.5;
}

/* Stamp Card Settings */
.milestone-stamp-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: stretch;
}

/* Stamp pattern toggle */
.stamp-count-toggle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 12px 16px;
  background: var(--cream);
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 20px;
}

.stamp-count-toggle__control {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.stamp-count-toggle__text {
  font-size: 14px;
  color: #666;
  user-select: none;
}

/* Stamp grid - always 5 columns */
.stamp-card-preview {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  padding: 16px;
  background: var(--cream);
  border-radius: 12px;
  margin-bottom: 20px;
}

.stamp-slot {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  font-size: 14px;
  min-width: 48px;
  min-height: 48px;
}

.stamp-slot:hover {
  border-color: var(--orange);
}

.stamp-slot.selected {
  background: var(--orange);
  color: white;
}

/* Preview Column */
.milestone-stamp__right-column {
  position: sticky;
  top: 24px;
  align-self: start;
}

.milestone-stamp__preview {
  background: var(--cream);
  border-radius: 15px;
  padding: 24px;
  margin-bottom: 24px;
}

.milestone-stamp__preview--full {
  height: calc(100vh - 400px);
  overflow-y: auto;
  margin-bottom: 0;
}

.milestone-stamp__preview-heading {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.milestone-stamp__preview-content {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.milestone-stamp__submit {
  margin-top: 20px;
}

/* Table styles */
.stamp-rewards-table {
  margin-top: 20px;
  width: 100%;
}

.stamp-rewards-table .ant-table {
  border-radius: 12px;
}

/* Fixed column widths */
.stamp-rewards-table .ant-table-thead > tr > th:first-child {
  width: 80px; /* Stamp number column */
}

.stamp-rewards-table .ant-table-thead > tr > th:last-child {
  width: 60px; /* Action column */
}

/* Select dropdown styles */
.stamp-rewards-table .ant-select {
  width: 100%;
}

/* Ensure select box takes full height */
.stamp-rewards-table .ant-select-selector {
  height: 100% !important; /* Take full height of row */
  min-height: 32px !important;
  display: flex;
  align-items: center;
}

/* Center content vertically */
.stamp-rewards-table .ant-select-selection-item {
  white-space: normal !important;
  line-height: 1.2 !important;
  padding-right: 24px !important; /* Space for dropdown arrow */
  display: flex !important;
  align-items: center;
  min-height: 32px !important;
  height: 100% !important;
}

/* Dropdown menu styles */
.stamp-rewards-table .ant-select-dropdown {
  width: fit-content !important;
  min-width: 200px !important;
}

.stamp-rewards-table .ant-select-item {
  padding: 8px !important;
  min-height: 32px !important;
  display: flex !important;
  align-items: center;
}

.stamp-rewards-table .ant-select-item-option-content {
  white-space: normal !important;
  line-height: 1.2 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Responsive styles */
@media (max-width: 1440px) {
  .milestone-stamp__body {
    grid-template-columns: 300px minmax(450px, 1fr) 380px;
    gap: 20px;
  }
}

@media (max-width: 1200px) {
  .milestone-stamp__body {
    grid-template-columns: 1fr 1fr;
  }

  .milestone-stamp__section:nth-child(2) {
    grid-column: 1 / -1;
    order: -1;
  }

  .milestone-stamp__right-column {
    grid-column: 1 / -1;
    position: static;
  }

  .milestone-stamp__preview {
    height: auto;
    max-height: 600px;
  }
}

@media (max-width: 768px) {
  .milestone-stamp__body {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .milestone-stamp__section {
    display: none;
  }

  .milestone-stamp__section--mobile-current {
    display: block;
  }

  .milestone-stamp__navigation {
    display: block;
    margin-top: 24px;
  }
}

.milestone-stamp-page {
  padding: 16px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.milestone-stamp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.milestone-stamp-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.preview-container {
  background: var(--cream);
  border-radius: 15px;
  padding: 16px;
  margin-top: 16px;
}

.preview-heading {
  margin-bottom: 16px;
}

.preview-content {
  background: white;
  border-radius: 8px;
  padding: 16px;
  max-height: 600px;
  overflow-y: auto;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .preview-container {
    padding: 12px;
  }

  .preview-content {
    padding: 12px;
  }
}

/* Make the milestone card preview larger */
.milestone-card {
  width: 100%;
  max-width: none;
}

.milestone-card__banner {
  padding: 24px;
}

.milestone-card__details {
  padding: 24px;
}

.milestone-card__section {
  padding: 20px;
  margin-bottom: 20px;
}

.milestone-card__stamps {
  gap: 12px;
}

.milestone-card__stamp {
  min-width: 52px;
  min-height: 52px;
  font-size: 16px;
}

.milestone-card__reward {
  padding: 16px;
}

/* Hide navigation by default */
.milestone-stamp__navigation {
  display: none;
}

/* Show navigation only on mobile */
@media (max-width: 768px) {
  .milestone-stamp__body {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .milestone-stamp__section {
    display: none;
  }

  .milestone-stamp__section--mobile-current {
    display: block;
  }

  .milestone-stamp__navigation {
    display: block;
    margin-top: 24px;
  }
}

/* Add to existing CSS */
.stamp-slot--final {
  background: var(--orange);
  color: white;
  cursor: not-allowed;
}

.stamp-slot--final:hover {
  border-color: transparent;
}

/* Adjust table column styles */
.stamp-rewards-table .ant-table-thead > tr > th:first-child {
  width: 80px; /* Make Stamp Number column even smaller */
}

.stamp-rewards-table .ant-select {
  width: 100%;
}

.stamp-rewards-table .ant-select-selection-item {
  white-space: normal !important;
  height: auto !important;
  min-height: 32px;
  max-height: none !important;
  padding: 6px 30px 6px 8px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.stamp-rewards-table .ant-select-item {
  padding: 5px 8px;
  height: auto !important;
}

.stamp-rewards-table .ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.stamp-rewards-table .ant-select-dropdown {
  min-width: 0 !important;
  max-width: none !important;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: var(--orange);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.delete-button:hover {
  background-color: #ff8533; /* Lighter orange */
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.delete-button svg {
  font-size: 16px;
}

/* Make main title smaller */
.milestone-stamp__heading .title {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Make section titles smaller */
.milestone-stamp__sub-heading .title {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Fix step number centering */
.step {
  display: flex;
  align-items: center;
  gap: 12px;
}

.step__number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
}

/* Adjust table column widths */
.stamp-rewards-table .ant-table-thead > tr > th:first-child {
  width: 80px; /* Fixed width for Slot column */
}

.stamp-rewards-table .ant-table-thead > tr > th:last-child {
  width: 100px; /* Fixed width for Action column */
}

/* Make the reward voucher column take remaining space */
.stamp-rewards-table .ant-table-thead > tr > th:nth-child(2) {
  min-width: 250px;
}

/* Update table select styles to handle long text */
.stamp-rewards-table .ant-select {
  width: 100%;
}

/* Style the selected item to show 2 lines */
.stamp-rewards-table .ant-select-selection-item {
  white-space: normal !important;
  height: auto !important;
  min-height: 32px;
  max-height: none !important;
  padding: 6px 30px 6px 8px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Style the dropdown options to show 2 lines */
.stamp-rewards-table .ant-select-item {
  padding: 5px 8px;
  height: auto !important;
}

.stamp-rewards-table .ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Reset dropdown width to match select width */
.stamp-rewards-table .ant-select-dropdown {
  min-width: 0 !important;
  max-width: none !important;
}

/* Keep column widths fixed */
.stamp-rewards-table .ant-table-thead > tr > th:first-child {
  width: 80px;
}

.stamp-rewards-table .ant-table-thead > tr > th:last-child {
  width: 100px;
}

.stamp-rewards-table .ant-table-thead > tr > th:nth-child(2) {
  min-width: 200px;
}
