.main-notify {
  width: 35px;
  height: 35px;
  background-color: var(--primary-colour);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.PremiumIndicator {
  position: absolute;
  bottom: 0; /* Position at the bottom */
  left: 0;
  border-radius: 0px 0px 0px 10px;
  pointer-events: none;
}
.main-notify-icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../../assets/icons/notification-96.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.dropdown-selected-program {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selected-program-header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 10px;
}

.selected-program-location {
  width: 90%;
  display: flex;
  justify-content: start;
  margin-top: 5px;
}

.location-icon {
  width: 15px;
  height: 12px;
  background-image: url("../../../../assets/icons/location.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.location-name {
  font-size: 12px;
}

.dropdown-divider {
  height: 1px;
  width: 90%;
  background-color: #333333;
  margin-block: 10px;
}

.selected-program-name {
  font-size: 18px;
  font-weight: 700;
}

.selected-program-type {
  font-size: 10px;
  color: gray;
}

.dropdown-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
}

.drop-icon {
  margin-inline: 10px;
  width: 50px;
  height: 50px;
  margin-right: 6px;
  border-radius: 5px;
}

.drop-add-icon {
  margin-inline: 10px;
  width: 50px;
  height: 50px;
  margin-right: 6px;
  border-radius: 5px;
  background-color: #33333360;

  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-icon {
  background-image: url("../../../../assets/icons/plus.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.dropdown-item-content {
  flex: 1;
  margin-right: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dropdown-program-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.dropdown-program-name {
  font-weight: 600;
}

.dropdown-program-location {
  display: flex;
  align-items: center;
  width: 100%;
}

.dropdown-program-type {
  font-size: 10px;
  color: gray;
}

.dropdown-location-name {
  font-size: 11px;
}

/* Hover styles for dropdown items */
.dropdown-item:hover {
  background-color: #e7e7e7; /* Light grey background on hover */
  cursor: pointer; /* Change cursor to indicate clickable */
  border-radius: 10px;
}

/* Optionally, change text color on hover */
.dropdown-item:hover span {
  color: #333333; /* Darker text color on hover */
}

.text-wrapper {
  position: relative;
  width: fit-content;
  font-family: "Nunito Sans", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.frame-wrapper {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 1398px;
  left: 656px;
  border-radius: 15px;
  overflow: hidden;
  border: 5px solid;
  border-color: #ffffff;
}
