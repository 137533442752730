.pass {
  position: relative;
  background-color: #cfb49f;
  color: #ffffff;
  border-radius: 11px;
}

.pass__head {
  padding: 17px 15px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.pass__logo-preview {
  flex-shrink: 0;
  margin-top: 1px;
}

.pass__logo {
  vertical-align: top;
  height: 37px;
  background-color: var(--image-fallback);
  border-radius: 8px;
}

.pass__summary {
}

.pass__heading {
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 22px;
}

.pass__location {
}

.pass__location .location {
  color: inherit;
}

.pass__hero {
}

.pass__image {
  vertical-align: top;
  width: 100%;
  height: auto;
  background-image: var(--image-fallback);
}

.pass__body {
  padding: 12px 16px 16px;
}

.pass__customer {
}

.pass__customer-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgb(0 0 0 / 80%);
}

.pass__customer-value {
  font-size: 28px;
  line-height: 28px;
}

.pass__data {
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.pass__param {
}

.pass__param:last-child {
  text-align: right;
}

.pass__param-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgb(0 0 0 / 80%);
}

.pass__param-value {
  font-size: 17px;
  line-height: 20px;
}

.pass__qr {
  width: fit-content;
  margin-top: 9px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 16px 12px;
  background-color: #ffffff;
  border-radius: 5px;
}

.pass__qr-image {
  vertical-align: top;
  width: 118px;
  height: 118px;
}

.pass__qr-number {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}

.pass::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  border: 1px solid rgb(0 0 0 / 16%);
  border-radius: 11px;
  pointer-events: none;
}

@media (900px <= width < 1500px) {
  .pass {
    /*position: relative;*/
    /*background-color: #CFB49F;*/
    /*color: #FFFFFF;*/
    /*border-radius: 11px;*/
  }

  .pass__head {
    padding: 12px;
    /*display: flex;*/
    /*align-items: center;*/
    /*gap: 16px;*/
  }

  .pass__logo-preview {
    /*flex-shrink: 0;*/
    /*margin-top: 1px;*/
  }

  .pass__logo {
    /*vertical-align: top;*/
    /*height: 37px;*/
    /*background-color: var(--image-fallback);*/
    /*border-radius: 8px;*/
  }

  .pass__summary {
  }

  .pass__heading {
    /*font-weight: var(--extra-bold);*/
    /*font-size: 18px;*/
    /*line-height: 22px;*/
  }

  .pass__location {
  }

  .pass__location .location {
    /*color: inherit;*/
  }

  .pass__hero {
  }

  .pass__image {
    /*vertical-align: top;*/
    /*width: 100%;*/
    /*height: auto;*/
    /*background-image: var(--image-fallback);*/
  }

  .pass__body {
    /*padding: 12px 16px 16px;*/
    padding-left: 12px;
    padding-right: 12px;
  }

  .pass__customer {
  }

  .pass__customer-label {
    /*font-weight: bold;*/
    /*font-size: 12px;*/
    /*line-height: 18px;*/
    /*text-transform: uppercase;*/
    /*color: rgb(0 0 0 / 80%);*/
  }

  .pass__customer-value {
    /*font-size: 28px;*/
    /*line-height: 28px;*/
  }

  .pass__data {
    /*margin-top: 15px;*/
    display: block;
    /*align-items: flex-start;*/
    /*justify-content: space-between;*/
  }

  .pass__param {
    margin-top: 12px;
  }

  .pass__param:last-child {
    text-align: left;
  }

  .pass__param-label {
    /*font-weight: bold;*/
    /*font-size: 12px;*/
    /*line-height: 14px;*/
    /*text-transform: uppercase;*/
    /*color: rgb(0 0 0 / 80%);*/
  }

  .pass__param-value {
    /*font-size: 17px;*/
    /*line-height: 20px;*/
  }

  .pass__qr {
    /*width: fit-content;*/
    margin-top: 12px;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    /*padding: 16px 16px 12px;*/
    /*background-color: #FFFFFF;*/
    /*border-radius: 5px;*/
  }

  .pass__qr-image {
    /*vertical-align: top;*/
    /*width: 118px;*/
    /*height: 118px;*/
  }

  .pass__qr-number {
    /*margin-top: 12px;*/
    /*font-size: 14px;*/
    /*line-height: 20px;*/
    /*color: #000000;*/
    /*text-align: center;*/
  }

  .pass::after {
    /*content: "";*/
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*display: block;*/
    /*border: 1px solid rgb(0 0 0 / 16%);*/
    /*border-radius: 11px;*/
    /*pointer-events: none;*/
  }
}
