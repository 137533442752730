@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

:root {
  --orange: #e06932;
  --charcoal: #333333;
  --cream: #ffeed7;
  --teal-green: #04b290;
}

* {
  font-family: "Questrial", sans-serif;
}
