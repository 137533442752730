.locations-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
}

.location-card {
  width: 100%; /* Ensures the card takes up the full width of the grid cell */
  height: 100%; /* Ensures the card takes up the full height of the grid cell */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.location-locationIcon {
  width: 10px;
  height: 12px;
}

.location-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.location-content {
  flex-grow: 1;
}

.edit-button {
  height: 40px;
  /* position: absolute;
  bottom: 0;
  margin: 15px 0px 15px 0px; */
}

.location-index {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
}

.create-new-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.location-name {
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.location-title {
  display: flex;
  flex-direction: row;
  align-self: start;
  align-items: center;
}

.create-new-location {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
}

.location-logo {
  width: 50px;
}

.create-new-text {
  font-weight: 500;
}

.qrcode-button {
  /* position: absolute;
  bottom: 0;
  margin: 15px 0px 15px 0px; */
  display: flex;
  align-items: center;
  height: 40px;
}
.qrcode-icon {
  margin-right: 5px;
}

.location-button {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.location-card.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .locations-container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

@media (max-width: 480px) {
  .locations-container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
