.approval.container {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  max-width: 1600px;
  /* margin: 0 auto; */
  text-align: center;
}

.approval__image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  margin-bottom: 30px;
}

.approval__button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
