.header {
  position: fixed;
  z-index: 1000;
  top: 8px;
  right: 14px;
  padding: 4px;
  background-color: #faf9fc;
  box-shadow: 0 1px 2px rgba(0 0 0 / 10%);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.header__premium {
  margin-left: 16px;
}

.header__plans {
  margin-left: 14px;
}

.header__plans .button__icon {
  margin-left: -4px;
}

.header__user {
  margin-left: 10px;
}

.header__user:first-child {
  margin-left: 0;
}

@media (900px <= width < 1500px) {
  .header {
    top: 8px;
    right: 12px;
    border-radius: 6px;
  }

  .header__premium {
    margin-left: 12px;
  }

  .header__plans {
    margin-left: 10px;
  }

  .header__plans .button__icon {
    margin-left: -3px;
  }

  .header__user {
    margin-left: 7px;
  }

  .header__user:first-child {
    margin-left: 0;
  }
}
