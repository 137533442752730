.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--black);
  color: var(--charcoal);
  font-size: 48px;
  line-height: 65px;
}

.title--secondary {
  font-size: 30px;
  line-height: 40px;
}
 
.title--tertiary {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}


@media (900px <= width < 1500px) {

    .title {
        font-size: 34px;
        line-height: 42px;
    }

    .title--secondary {
        font-size: 22px;
        line-height: 26px;
    }

    .title--tertiary {
        font-size: 16px;
        line-height: 22px;
    }
}
