.steps-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e06932;
  box-shadow: var(--shadow-over-grey-background);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.steps-nav__action {
  border: none;
  padding: 15px 32px;
  background-color: transparent;
  cursor: pointer;
}
 
.steps-nav__action[disabled] {
    opacity: 50%;
    cursor: default;
}

.steps-nav__chevron {
    vertical-align: top;
    fill: #FFFFFF;
}

.steps-nav__counter {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}
