.success-main {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7px);
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .success-inner-div {
    width: 350px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .c-icon {
  
      top: 15px;
      right: 15px;
  
      position: absolute;
    width: 25px;
    height: 25px;
  
    background-image: url('../../assets/icons/close-60.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

.success-content{
    /* background-color: blueviolet; */
    margin-top: 20px;
    width: 85%;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-h1{
    margin-top: 10px;
    font-size: 36px;
    font-weight: 800;
    color: #FF780F;
}

.success-h2{
    color: gray;
}

.success-img{
    width: 100%;
    height: 250px;
    background-image: url('../../assets/images/SUCCESS.png');
    background-size: contain;
    background-repeat: no-repeat;
}