.inner-scroll {
    position: relative;
    height: calc(100% - 12px);
    min-height: 336px;
    background-color: #EDF1FF;
}

.inner-scroll__scroll {
    position: absolute;
    left: -12px;
    right: -15px; /* 12px + half of scrollbar width */
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 6px 12px;
}

.inner-scroll__scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #E5E5E5;
    border-radius: 3px;
}

/* Add a thumb */
.inner-scroll__scroll::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 3px;
}

.inner-scroll__scroll::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
}

.inner-scroll__scroll::-webkit-scrollbar-thumb:active {
    background-color: #888;
}

.inner-scroll__ribbon {

}

.inner-scroll__fade {
    position: absolute;
    left: -12px;
    right: -9px; /* 12px minus half width */
    height: 40px;
    transition: opacity var(--transition-show),
    visibility var(--transition-show);
    pointer-events: none;
}

.inner-scroll__fade--top {
    top: 0;
    background-image: linear-gradient(#FFFFFF, rgb(255 255 255 / 0));
}

.inner-scroll__fade--bottom {
    bottom: 0;
    background-image: linear-gradient(rgb(255 255 255 / 0), #FFFFFF);
}


.inner-scroll__handler {
    position: absolute;
    left: 0;
    right: 0;
    vertical-align: top;
    margin: auto;
    width: 80px;
    height: 80px;
    padding: 15px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color var(--transition-hover),
    opacity var(--transition-show),
    visibility var(--transition-show);
}

.inner-scroll__handler:hover {
    background-color: var(--hover);
    box-shadow: var(--shadow-over-white-background)
}

.inner-scroll__handler:active {
    background-color: var(--active);
    transition: none;
}

.inner-scroll__chevron {
    width: 100%;
    height: auto;
}

.inner-scroll__handler--top {
    top: 0;
}

.inner-scroll__handler--bottom {
    bottom: 0;
}


/* These two classes:
 * .inner-scroll--scrolled-to-top
 * .inner-scroll--scrolled-to-bottom
 * can be used as an alternative to React's DOM manipulation.
 * Instead of adding/removing fades and buttons to the DOM
 * we can toggle these classes and elements will be shown/hidden
 * by styles. This solution can be better because we can add
 * opacity transition here.
 */

.inner-scroll--scrolled-to-top {

}

.inner-scroll--scrolled-to-top .inner-scroll__fade--top {
    opacity: 0;
    visibility: hidden;
}

.inner-scroll--scrolled-to-top .inner-scroll__handler--top {
    opacity: 0;
    visibility: hidden;
}


.inner-scroll--scrolled-to-bottom {

}

.inner-scroll--scrolled-to-bottom .inner-scroll__fade--bottom {
    opacity: 0;
    visibility: hidden;
}

.inner-scroll--scrolled-to-bottom .inner-scroll__handler--bottom {
    opacity: 0;
    visibility: hidden;
}


@media (900px <= width < 1500px) {

    .inner-scroll {
        height: calc(100% - 12px);
        min-height: 293px;
    }

    .inner-scroll__scroll {
        left: -8px;
        right: -11px; /* 8px + half of scrollbar width */
        padding: 6px 8px;
    }

    .inner-scroll__ribbon {

    }

    .inner-scroll__fade {
        left: -8px;
        right: -5px; /* 8px minus half width */
        height: 30px;
    }

    .inner-scroll__handler {
        width: 60px;
        height: 60px;
        padding: 11px;
    }

}
