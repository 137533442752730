.tooltip {
    display: inline-block;
    vertical-align: top;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease, /* hide */ visibility 0.1s ease; /* without delay */
}

.tooltip__body {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 14px 18px;
    background-color: var(--tooltip-background);
    border-radius: 6px;
    font-family: var(--font); /* in case it's inside of .button */
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    text-transform: none; /* если внутри кнопки */
    color: #FFFFFF;
    white-space: nowrap;
}


.tooltip-trigger:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease 0.1s, /* show */ visibility 0.1s ease 0.1s; /* with delay */
}

.tooltip-trigger:focus-within .tooltip {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease 0.1s, /* show */ visibility 0.1s ease 0.1s; /* with delay */
}

/* class to show them manually */
.tooltip--visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease 0.1s, /* show */ visibility 0.1s ease 0.1s; /* with delay */
}

.tooltip--normal-wrap {

}

.tooltip--normal-wrap .tooltip__body {
    white-space: normal;
}


.tooltip--top {
    position: absolute;
    z-index: 4000; /* why higher than header? */
    right: 50%;
    bottom: 100%;
    transform: translateX(50%);
    margin-bottom: 5px;
}

.tooltip--top .tooltip__body {

}

.tooltip--top .tooltip__peak {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -5px;
    border-top: 5px solid var(--tooltip-background);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}


.tooltip--right {
    position: absolute;
    z-index: 90;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 5px;
}

.tooltip--right .tooltip__body {

}

.tooltip--right .tooltip__peak {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-right: 5px solid var(--tooltip-background);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}


.tooltip--bottom {
    position: absolute;
    z-index: 90;
    right: 50%;
    top: 100%;
    transform: translateX(50%);
    margin-top: 5px;
}

.tooltip--bottom .tooltip__body {

}

.tooltip--bottom .tooltip__peak {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-left: -6px;
    border-bottom: 5px solid var(--tooltip-background);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}


.tooltip--left {
    position: absolute;
    z-index: 90;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 5px;
}

.tooltip--left .tooltip__body {

}

.tooltip--left .tooltip__peak {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-left: 5px solid var(--tooltip-background);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}

