.step {

}

.step__index {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  width: 50px;
  height: 50px;
  margin-top: -5px;
  margin-bottom: -5px;
  background-color: var(--primary-colour);
  border-radius: 50%;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
}

.step--completed .step__index {
  background-color: var(--teal-green);
}

.step__tick {
  vertical-align: top;
  width: 30px;
  height: 30px;
  fill: currentColor;
}

@media (900px <= width < 1500px) {
  .step {
  }

  .step__index {
    margin-right: 9px;
    width: 36px;
    height: 36px;
    font-size: 22px;
    line-height: 22px;
  }

  .step__tick {
    width: 20px;
    height: 20px;
  }
}

@media (width < 900px) {
  .step {
  }

  .step__index {
    margin-top: 2px;
    width: 34px;
    height: 34px;
    font-size: 24px;
    line-height: 24px;
  }

  .step--completed .step__index {
  }

  .step__tick {
    width: 22px;
    height: 22px;
  }
}
