.premium {
  position: relative;
  margin: 0 auto;
  background-color: #ffffff;
  color: #333333;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  padding: 40px 56px 32px;
  border-radius: 30px;
}

.premium__toggle {
  display: flex;
  justify-content: flex-end;
}

.premium__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: #333333;
  font-size: 48px;
  line-height: 48px;
}

.premium__body {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 360px 360px 360px;
  gap: 30px;
}

.premium__plan {
  display: flex;
  flex-direction: column; /* Ensure space between items */
  height: 100%;
  padding: 24px 27px 56px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.premium__plan--has-frame {
  box-shadow:
    0 4px 10px rgb(0 0 0 / 25%),
    inset 0 0 0 8px var(--brand);
}

.premium__plan-heading {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  line-height: 48px;
}

.premium__plan-body {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 25px;
}

.premium__variant {
  flex-grow: 1;
  flex-basis: 0;
}

.premium__price {
  position: relative;
}

.premium__price-panel {
  position: relative;
  z-index: 10;
  padding: 16px 12px 24px;
  background-color: var(--brand);
  box-shadow: inset 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
  color: #ffffff;
}

.premium__dollar {
  font-weight: var(--light);
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.premium__value {
  color: var(--brand);
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
}

.premium__ribbon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px; /* Adjust the height as needed */
  margin-top: 10px; /* Adjust the margin as needed */
}

.premium__ribbon {
  position: absolute;
  width: 161%;
  height: 161%;
  object-fit: contain;
}

.premium__ribbon-text {
  position: relative;
  color: white; /* Adjust the text color as needed */
  font-weight: bold;
  font-size: 16px; /* Adjust the font size as needed */
  text-align: center;
  z-index: 1; /* Ensure the text is above the ribbon */
}

.premium__ribbon-text-container {
  display: flex;
  flex-direction: column;
}

.premium__period {
  color: var(--brand);
  margin-top: -6px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.premium__best-value {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -20px;
  padding: 17px 10px 4px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #333333;
  font-weight: var(--black);
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
}

.premium__price--has-best-value {
  padding-bottom: 7px; /* this padding */
}

.premium__price--has-best-value .premium__price-panel {
  padding-bottom: 17px; /* 7px less height */
}

.premium__submit {
  margin-top: auto; /* Push the submit button to the bottom */
  display: flex;
  justify-content: flex-end; /* Align the button to the flex-end */
}

.premium__submit .button {
  width: 100%;
}

.premium__list {
  margin-top: 16px;
}

.premium__item {
  margin-top: 8px;
  padding-left: 27px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogbm9uZTsKICAgICAgICBzdHJva2U6ICMwNGIyOTA7CiAgICAgICAgc3Ryb2tlLW1pdGVybGltaXQ6IDEwOwogICAgICB9CgogICAgICAuY2xzLTIgewogICAgICAgIGZpbGw6ICMwNGIyOTA7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiAwcHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+CiAgICA8Zz4KICAgICAgPHBhdGggY2xhc3M9ImNscy0yIiBkPSJNNS40MSwxNy40MmMxLjg1LTEuMjksMy43LTIuNTcsNS41NS0zLjg2LDEuNDEsMi42OSwyLjgyLDUuMzgsNC4yMyw4LjA3LDIuODEtNi4xOCw4LjY3LTEwLjg3LDE1LjMxLTEyLjI3LTYuOTcsNC40My0xMi4yMSwxMS41Mi0xNC40LDE5LjQ5LTMuNTUtMy44Ny03LjA5LTcuNzQtMTAuNjQtMTEuNjEiLz4KICAgICAgPGNpcmNsZSBjbGFzcz0iY2xzLTEiIGN4PSIxNy41IiBjeT0iMTcuNSIgcj0iMTciLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPg==);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 18px;
  line-height: 25px;
}

@media (900px <= width < 1500px) {
  .premium {
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
    padding: 30px 42px 24px;
    border-radius: 22px;
  }

  .premium__heading {
    font-size: 36px;
    line-height: 36px;
  }

  .premium__body {
    margin-top: 13px;
    grid-template-columns: 270px 270px 270px;
    gap: 22px;
  }

  .premium__plan {
    padding: 21px 20px 42px;
    box-shadow: 0 3px 7px rgb(0 0 0 / 25%);
    border-radius: 11px;
  }

  .premium__plan--has-frame {
    box-shadow:
      0 3px 8px rgb(0 0 0 / 25%),
      inset 0 0 0 6px var(--brand);
  }

  .premium__plan-heading {
    font-size: 28px;
    line-height: 26px;
  }

  .premium__plan-body {
    margin-top: 15px;
    gap: 18px;
  }

  .premium__variant {
  }

  .premium__price {
  }

  .premium__price-panel {
    padding: 12px 9px 18px;
    box-shadow: inset 0 3px 3px rgb(0 0 0 / 25%);
    border-radius: 11px;
  }

  .premium__dollar {
    font-size: 27px;
    line-height: 27px;
  }

  .premium__value {
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    text-align: center;
  }

  .premium__period {
    margin-top: -4px;
    font-size: 16px;
    line-height: 22px;
  }

  .premium__best-value {
    margin-top: -15px;
    padding: 12px 7px 3px;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    font-size: 14px;
    line-height: 18px;
  }

  .premium__price--has-best-value {
    padding-bottom: 5px; /* this padding */
  }

  .premium__price--has-best-value .premium__price-panel {
    padding-bottom: 13px; /* 7px less height */
  }

  .premium__submit__paypal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the container takes up the full width */
    max-width: 400px; /* Set a maximum width for the button */
    margin-top: 28px;
    margin-bottom: 0;
  }

  .paypal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #04b290; /* Adjust the overlay color and opacity */
    border-radius: 5px; /* Adjust the border radius to match other buttons */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Adjust the text color */
    font-weight: bold;
    font-size: 16px; /* Adjust the font size */
    cursor: pointer;
    z-index: 999; /* Ensure the overlay is above the PayPal button */
    pointer-events: none; /* Make the overlay transparent to clicks */
  }

  .premium__list {
    margin-top: 16px;
  }

  .premium__item {
    margin-top: 6px;
    padding-left: 20px;
    background-size: 14px;
    font-size: 15px;
    line-height: 20px;
  }

  .premium__ribbon {
    position: absolute;
    width: 125%;
    height: 125%;
    object-fit: contain;
  }
}
