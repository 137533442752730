.onboarding-form {
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
}

.onboarding-form__progress {
  background: var(--cream);
  height: 6px;
  border-radius: 3px;
  margin-bottom: 40px;
  overflow: hidden;
}

.onboarding-form__progress-bar {
  background: var(--orange);
  height: 100%;
  transition: width 0.4s ease;
}

.onboarding-form__content {
  background: white;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  animation: slideUp 0.5s ease;
}

.onboarding-form__title {
  color: var(--charcoal);
  font-size: 28px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
}

.onboarding-form__fields {
  animation: fadeIn 0.4s ease;
}

.onboarding-form__field {
  margin-bottom: 24px;
}

.onboarding-form__field label {
  color: var(--charcoal);
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.onboarding-form input,
.onboarding-form__select,
.onboarding-form__textarea {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
}

.onboarding-form input:focus,
.onboarding-form__select:focus,
.onboarding-form__textarea:focus {
  border-color: var(--orange);
  outline: none;
  box-shadow: 0 0 0 3px var(--cream);
}

.onboarding-form__textarea {
  min-height: 120px;
  resize: vertical;
}

.onboarding-form__input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.onboarding-form__addon {
  color: var(--charcoal);
  font-weight: 500;
}

.onboarding-form__actions {
  display: flex;
  gap: 16px;
  margin-top: 40px;
  justify-content: flex-end;
}

.onboarding-form__button {
  padding: 12px 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.onboarding-form__button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.onboarding-form__button--secondary {
  background: var(--cream);
  color: var(--orange);
  border: none;
}

.onboarding-form__button--secondary:hover:not(:disabled) {
  background: #ffe0c0;
}

.onboarding-form__button:not(.onboarding-form__button--secondary) {
  background: var(--orange);
  color: white;
  border: none;
}

.onboarding-form__button:not(.onboarding-form__button--secondary):hover:not(
    :disabled
  ) {
  background: #d45a25;
}

.onboarding-form__image-upload {
  border: 2px dashed #e1e1e1;
  border-radius: 12px;
  padding: 24px;
  transition: all 0.2s ease;
}

.onboarding-form__image-upload:hover {
  border-color: var(--orange);
  background: var(--cream);
}

.onboarding-form__note {
  color: #666;
  font-size: 14px;
  margin-top: 8px;
}

.onboarding-form__date-picker {
  background: white;
  border: 2px solid #e1e1e1;
  border-radius: 12px;
  padding: 24px;
}

/* Progress Modal Styles */
.onboarding-form__progress-steps {
  padding: 24px 0;
}

.onboarding-form__progress-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.onboarding-form__progress-step:last-child {
  border-bottom: none;
}

.onboarding-form__progress-step-title {
  color: var(--charcoal);
  font-weight: 500;
}

.onboarding-form__progress-step .success {
  color: var(--teal);
  font-size: 20px;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* CSS Variables */
:root {
  --orange: #e06932;
  --charcoal: #333333;
  --cream: #ffeed7;
  --teal: #04b290;
}

.onboarding-form__color-picker {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding: 16px;
  background: white;
  border: 2px solid #e1e1e1;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.onboarding-form__color-picker:hover {
  border-color: var(--orange);
  background: var(--cream);
}

.onboarding-form__color-code {
  font-family: monospace;
  font-size: 14px;
  color: var(--charcoal);
  padding: 8px 12px;
  background: #f5f5f5;
  border-radius: 4px;
}

/* Override antd color picker styles */
.onboarding-form__color-picker .ant-color-picker-trigger {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  border: 2px solid #e1e1e1;
}

.onboarding-form__color-picker .ant-color-picker-trigger:hover {
  border-color: var(--orange);
}

.onboarding-form__description {
  white-space: pre-wrap;
  margin-bottom: 24px;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.onboarding-form__header {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.onboarding-form__brand-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0;
  text-align: center;
  margin-top: 100px;
}

.onboarding-form__logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  position: absolute;
  top: 0px;
  right: 10px;
}
