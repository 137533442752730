.add-program-bar {
  margin-left: -12px;
  margin-right: -12px;
  width: calc(100% + 24px);
  display: flex;
  align-items: center;
  gap: 20px;
  border: none;
  border-radius: 6px;
  padding: 12px;
  background-color: transparent;
  /* font-family: inherit; */
  font-family: "Questrial", sans-serif;
  text-align: left;
  letter-spacing: inherit;
  cursor: pointer;
}

.add-program-bar:hover {
  background-color: var(--hover-lighter);
}

.add-program-bar:active {
  background-color: var(--active-lighter);
}

.add-program-bar--disabled,
.add-program-bar--disabled:hover {
  background-color: #ddd;
}

.add-program-bar--disabled > * {
  opacity: 0.6;
}

.add-program-bar__preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #b7bdc2;
  border-radius: 8px;
}

.add-program-bar__plus {
  vertical-align: top;
  fill: #000000;
}

.add-program-bar__label {
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 22px;
}
