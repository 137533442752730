.membership-index {
  /* vertical centering: */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.membership-index__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.membership-index__body {
  margin-top: var(--spacing);
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: var(--spacing);
}

.membership-index__item {
}

.membership-index__item .add {
  min-height: 100%;
}

@media (width < 900px) {
  .membership-index {
  }

  .membership-index__heading {
  }

  .membership-index__body {
    display: block;
  }

  .membership-index__item {
    margin-top: var(--spacing);
  }

  .membership-index__item .add {
    width: 100%;
  }
}
