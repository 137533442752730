.container {
    width: 100%;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
}


@media (width < 900px) {
    .container {
        border-left-width: 50px;
        border-right-width: 50px;
    }
}
