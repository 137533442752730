.program-bar {
  margin-left: -12px;
  margin-right: -12px;
  /*width: calc(100% + 24px);*/
  width: 300px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: none;
  border-radius: 6px;
  padding: 12px;
  background-color: transparent;
  font-family: inherit;
  text-align: left;
  letter-spacing: inherit;
  cursor: pointer;
}

.program-bar:not(.program-bar--current):hover {
  background-color: var(--hover-lighter);
}

.program-bar:not(.program-bar--current):active {
  background-color: var(--active-lighter);
}

.program-bar__preview {
  flex-shrink: 0;
}
.program-bar__logo {
  vertical-align: top;
  width: 40px;
  background-color: var(--image-fallback);
  border-radius: 6px;
}

.program-bar__body {
  flex-grow: 1;
  padding-top: 1px;
  padding-bottom: 1px;
}
.program-bar__name {
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 22px;
}

.program-bar__location {
  margin-top: 1px;
}

.program-bar__category {
  width: min-content;
  font-size: 12px;
  line-height: 12px;
  color: #b7bdc2;
  text-align: right;
}

.program-bar--current {
  /*margin-left: -12px;*/
  /*margin-right: -12px;*/
  /*width: calc(100% + 24px);*/
  /*display: flex;*/
  /*align-items: center;*/
  /*gap: 20px;*/
  /*border: none;*/
  /*border-radius: 6px;*/
  /*padding: 12px;*/
  /*background-color: transparent;*/
  /*font-family: inherit;*/
  /*text-align: left;*/
  /*letter-spacing: inherit;*/
  /*cursor: pointer;*/
  cursor: default;
}

.program-bar--current:hover {
  /*background-color: var(--hover-lighter);*/
}

.program-bar--current:active {
  /*background-color: var(--active-lighter);*/
}

.program-bar--current .program-bar__preview {
  /*flex-shrink: 0;*/
}
.program-bar--current .program-bar__logo {
  /*vertical-align: top;*/
  /*width: 40px;*/
  /*background-color: var(--image-fallback);*/
  /*border-radius: 6px;*/
}

.program-bar--current .program-bar__body {
  /*flex-grow: 1;*/
  /*padding-top: 1px;*/
  /*padding-bottom: 1px;*/
}
.program-bar--current .program-bar__name {
  /*font-weight: var(--extra-bold);*/
  font-size: 20px;
  line-height: 24px;
}

.program-bar--current .program-bar__location {
  margin-top: 2px;
}

.program-bar--current .program-bar__category {
  /*width: min-content;*/
  font-size: 13px;
  line-height: 13px;
  /*color: #B7BDC2;*/
  /*text-align: right;*/
}
